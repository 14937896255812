import { AuthResponse } from './../interfaces/auth/auth-response';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base-service';
import { Observable } from 'rxjs';
import { WS_BASE } from '../constants/urls';
import { catchError, retry } from 'rxjs/operators';
import { AuthenticatedUser, SimpleUser } from '../interfaces/auth/simple-user';
import { SimpleLocation, UserLocationDto } from '../interfaces/simple-location';
import { BulkUserRequest, BulkUserResponse, UpdateUserDto } from '../../pages/internal/bulk-users/interfaces/bulk';
import { BulkUserRequestV2 } from 'app/pages/internal/bulk-users/upload-file/upload-file.schemas';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    getAuthenticatedUser() {
        return this.http
            .get<AuthenticatedUser>(WS_BASE + '/v1/users/authenticated-user')
            .pipe(retry(3), catchError(this.handleError));
    }

    getUser(id: number): Observable<SimpleUser> {
        return this.http.get<SimpleUser>(WS_BASE + '/v1/users/' + id).pipe(retry(3), catchError(this.handleError));
    }

    refreshToken() {
        const url = `${WS_BASE}/v1/users/refresh-token`;
        return this.http.get<AuthResponse>(url);
    }

    getAdminUsers(): Observable<SimpleUser[]> {
        return this.http
            .get<SimpleUser[]>(WS_BASE + '/v1/users/admin-users')
            .pipe(retry(3), catchError(this.handleError));
    }

    createUser(user: SimpleUser): Observable<SimpleUser> {
        return this.http
            .post<SimpleUser>(WS_BASE + '/v1/users/admin-users', user)
            .pipe(retry(0), catchError(this.handleError));
    }

    getUsersForLocation(locationId: number): Observable<SimpleUser[]> {
        return this.http
            .get<SimpleUser[]>(WS_BASE + '/v1/users/admin-users/locations/' + locationId)
            .pipe(retry(0), catchError(this.handleError));
    }

    getLocationsForUser(userId: number): Observable<UserLocationDto[]> {
        return this.http
            .get<UserLocationDto[]>(WS_BASE + '/v1/users/admin-users/' + userId + '/locations')
            .pipe(retry(3), catchError(this.handleError));
    }

    attachLocation(userId: number, locationId: number) {
        return this.http
            .put<any>(WS_BASE + '/v1/users/admin-users/' + userId + '/locations/' + locationId, null)
            .pipe(retry(2), catchError(this.handleError));
    }

    detachLocation(userId: number, locationId: number) {
        return this.http
            .delete<any>(WS_BASE + '/v1/users/admin-users/' + userId + '/locations/' + locationId)
            .pipe(retry(2), catchError(this.handleError));
    }

    resetPassword(username, hours): Observable<any> {
        return this.http
            .post<any>(WS_BASE + '/public/v1/password-reset', { username, hours })
            .pipe(retry(0), catchError(this.handleError));
    }

    getLocationUsers(locationId: number): Observable<SimpleUser> {
        return this.http
            .get<SimpleUser>(WS_BASE + '/v1/users/admin-users/locations/' + locationId)
            .pipe(retry(0), catchError(this.handleError));
    }

    updatePassword(
        reference: number,
        token: string,
        newPassword: string,
        errorCallback: (err: string) => void
    ): Observable<any> {
        return this.http
            .post<any>(WS_BASE + '/public/v1/password-reset/' + reference, { reference, token, newPassword })
            .pipe(
                retry(0),
                catchError(err => {
                    errorCallback(err.error);
                    return err;
                })
            );
    }

    createBulkUsers(locations: string, mails: string): Observable<BulkUserResponse> {
        const request = { locations, mails } as BulkUserRequest;

        return this.http
            .post<BulkUserResponse>(`${WS_BASE}/v1/bulk/users`, request)
            .pipe(retry(0), catchError(this.handleError));
    }

    createBulkUsersV2(bulkUsers: BulkUserRequestV2[]): Observable<BulkUserResponse> {
        return this.http
            .post<BulkUserResponse>(`${WS_BASE}/v2/bulk/users`, bulkUsers)
            .pipe(retry(0), catchError(this.handleError));
    }

    updateUser(userId: number, updateUserDto: UpdateUserDto): Observable<SimpleUser> {
        const url = `${WS_BASE}/v1/users/admin-users/${userId}`;
        return this.http.put<SimpleUser>(url, updateUserDto).pipe(catchError(this.handleError));
    }
}
