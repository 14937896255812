import { HttpHeaders } from '@angular/common/http';

export const environment = {
    environment: 'prod',
    production: true,
    server_url: 'https://favrit.com/ws/ordr-service',
    onboarding: 'https://onboarding.favrit.com',
    payment: 'https://payment.favrit.com',
    productlib: 'https://favrit.com/ws/product-lib/public-api',
    accounting_service: 'https://favrit.com/ws/accounting-service',
    epos_url: 'https://epos.favrit.com',
    unibill_host: 'https://bill.favrit.com',
    enable_auth: true,
    enable_sentry: false,
    versionCheckURL: null,
    app_base: 'https://favrit.com',
    cloudinary_cloud_name: 'dkjgwja0c',
    cloudinary_api_key: '824345894193677',
    fortnox_authorization_link:
        'https://apps.fortnox.se/oauth-v1/auth?client_id=35E4WnG35SGx&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/fortnox&scope=bookkeeping%20archive%20connectfile%20costcenter&state=STATE_VALUE&access_type=offline&response_type=code',
    visma_authorization_link:
        'https://identity.vismaonline.com/connect/authorize?client_id=ordr&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/e-accounting&scope=ea:api%20offline_access%20ea:accounting&state=STATE_VALUE&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true',
    visma_e_ekonomi_authorization_link:
        'https://identity-sandbox.test.vismaonline.com/connect/authorize?client_id=ordr1&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/visma_e_ekonomi&scope=ea:api%20offline_access%20ea:accounting&state=STATE_VALUE&response_type=code&prompt=login&acr_values=service:44643EB1-3F76-4C1C-A672-402AE8085934+forceselectcompany:true',
    fiken_authorization_link:
        'https://fiken.no/oauth/authorize?response_type=code&client_id=MCp5hcyPfgaL7k8y87686517182067542&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/fiken&state=STATE_VALUE',
    softrig_authorization_link:
        'https://login.unieconomy.no/connect/authorize?client_id=ca306766-6e7d-8d4c-d724-d6d750d88d25&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/softrig&response_type=code&prompt=login&scope=profile openid AppFramework Accounting.Journal offline_access&state=STATE_VALUE',
    planday_authorization_link:
        'https://id.planday.com/connect/authorize?client_id=27ecee83-cb30-4290-9388-2cf4d2963396&response_type=code&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/planday&scope=openid department:create department:delete department:read department:update revenue:delete revenue:read revenue:set offline_access&state=STATE_VALUE',
    accounting_api_service: 'https://favrit.com/ws/accounting-api-service',
    sage_authorization_link:
        'https://www.sageone.com/oauth2/auth/central?filter=apiv3.1&response_type=code&client_id=51e488ec-2977-46ff-bfcb-b783dd07f6a4/44debd7b-92db-4cd1-8139-a96c5c69b888&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/sage&scope=full_access&state=STATE_VALUE',
    quickbooks_authorization_link:
        'https://appcenter.intuit.com/connect/oauth2?client_id=AB48XI1RSLDgjg5I1QCOtunJc8tNQasvSuFnYtyV7GQZmabVbU&response_type=code&scope=com.intuit.quickbooks.accounting&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/quickbooks&state=STATE_VALUE',
    xero_authorization_link:
        'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=1BC519469895482986B2F5AA64C078E1&redirect_uri=https://favrit.com/ws/accounting-service/public/integration/accounting/xero&scope=openid profile email offline_access accounting.transactions accounting.settings accounting.attachments&state=STATE_VALUE'
};
